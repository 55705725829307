import clsx from 'clsx';
import { Icon } from '@iconify/react';
import React, { useRef, useState } from 'react';
import homeFill from '@iconify-icons/eva/home-fill';
import PopoverMenu from 'src/components/PopoverMenu';
import { Link as RouterLink } from 'react-router-dom';
import personFill from '@iconify-icons/eva/person-fill';
import settings2Fill from '@iconify-icons/eva/settings-2-fill';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { logoutUser } from '../../../redux/saga/request/logout';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Avatar,
  Button,
  Divider,
  MenuItem,
  Typography
} from '@material-ui/core';
import { MIconButton } from 'src/theme';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/'
  },
  {
    label: 'Profile',
    icon: personFill,
    linkTo: '#'
  },
  {
    label: 'Settings',
    icon: settings2Fill,
    linkTo: '#'
  }
];

const useStyles = makeStyles((theme) => ({
  menuItem: {
    ...theme.typography.body2,
    padding: theme.spacing(1, 2.5)
  },
  btnAvatar: {
    padding: 0,
    width: 44,
    height: 44
  },
  isSelected: {
    '&:before': {
      zIndex: 1,
      content: "''",
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      background: alpha(theme.palette.grey[900], 0.8)
    }
  }
}));

// ----------------------------------------------------------------------

function Account() {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const { role, username } = useSelector((state) => state.login);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const onSubmit = () => {
    dispatch(logoutUser());
  };  

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        className={clsx(classes.btnAvatar, { [classes.isSelected]: isOpen })}
      >
        <Avatar
          alt="My Avatar"
          src="/static/images/avatars/avatar_default.jpg"
        />
      </MIconButton>

      <PopoverMenu
        width={220}
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ my: 2, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {username}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {role}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button onClick={onSubmit} fullWidth color="inherit" variant="outlined">
            Logout
          </Button>
        </Box>
      </PopoverMenu>
    </>
  );
}

export default Account;
